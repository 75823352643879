import React, {Fragment} from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    Datagrid,
    Edit,
    FormDataConsumer,
    List, minLength,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useQueryWithStore
} from 'react-admin';
import {DateInput, DateTimeInput} from 'react-admin-date-inputs2';
import {useForm} from "react-final-form";
import exporterCommon from "../import/exporterCommon";
import {RedirectToolbar} from "./common";
import {getScope, scopeResourcesEnum} from "./helperFunctions";
import Grid from "@material-ui/core/Grid";


export const DeductionList= props => (
    <List  exporter={exporterCommon(props.resource)} {...props}>
        <Datagrid >
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);




export const DeductionEdit = props => {
    // const { scopeId: scopeId_string, scope } =parse(props.location.search);
    // let  scopeId = scopeId_string ? parseInt(scopeId_string, 10) : "";
    // if(+scope===0)
    //     scopeId = scopeId_string;
    //
    //
    // const redirect = scopeId ? `/${scopeResourcesEnum[+scope]}/${scopeId}/show/deductions` : "show";
    // //  console.log('creating deduction',scopeId)
    // const scopeText = scopeEnum[+scope] || '';
    const {redirect,scopeText,scopeId,scopeName,scope} = getScope(props,"deductions")

    return <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<RedirectToolbar/>}>
            <FormDataConsumer>
                {formDataProps => (
                    <DeductionForm {...formDataProps} scopeText={scopeText} scope={scope} />
                )}
            </FormDataConsumer>

        </SimpleForm>
    </Edit>
};

// const scopeEnum = ['Company','Position', 'Employee','Template']
//
// const scopeResourcesEnum = ['companies','positions', 'employees','companies']

const choices=[
{ id: 'EmpPension', name: 'Pension (Employee)' },
{ id: 'PF', name: 'Provident Fund' },
    { id: 'loan', name: 'Repayment' },
{ id: 'Other', name: 'Other' },
    { id: 'CostSharing', name: 'Cost Sharing' },


]
const DeductionForm  = ({ formData, scopeText,scope, ...rest }) => {

    const form = useForm();

    const {data: templates} = useQueryWithStore({
        type: 'getList',
        resource: 'deductions',
        payload: {filter:{filter:`Scope eq 'Template'`},pagination:{},sort:{}}
    });


    const { loaded, error, data: loans } = useQueryWithStore({
        type: 'getList',
        resource: 'loans',
        payload: {filter:{filter:`employeeId eq ${formData.employeeId} and LoanStatus eq 'Standing'`},pagination:{},sort:{}}
    });



   return <Fragment>
       <Grid container style={{width:"100%"}}>
        <ReferenceInput {...rest}
            source={`${scopeText.toLowerCase()}Id`}
            reference={scopeResourcesEnum[+scope]}
            validate={required()}
            disabled
        >
            <SelectInput optionText="name" />
        </ReferenceInput>

       <SelectInput label="Template" onChange={event=>{
           const item = templates.find(e=>e.id===event.target.value)
           form.change('name',item.name)
           form.change('deductionType',item.deductionType)
           form.change('valueType',item.valueType)
           form.change('cash',item.cash)
           // form.change('templateId',item.id)
           form.change('dayBased',item.dayBased)
       }}   {...rest} source="templateId"  choices={templates}  />



        <SelectInput {...rest} source="deductionType" onChange={value=>{
            form.change('name',choices.find(c => c.id===value.target.value).name)
            form.change('cash',choices.find(c => c.id===value.target.value).id==='loan')
        }
        }  choices={choices}  />
       <TextInput {...rest} source="name"  validate={[required(),minLength(2)]}  />
       <ReferenceInput perPage={1000}    source="costCenterId" reference="costCenters" >
           {/*<SelectInput  validate={[required()]} optionText="name"  />*/}
           <AutocompleteInput  optionText="name" />

       </ReferenceInput>
   </Grid>
       {formData.deductionType!=='IncomeTax' && (
           <Fragment>
           <SelectInput {...rest} source="valueType"  choices={[
               { id: 'Absolute', name: 'Absolute' },
               { id: 'Percentage', name: 'Percentage' },
           ]}  />

           {/*<NumberInput {...rest} source="value" />*/}

               {+scope!==3&&<NumberInput format={v => formData.valueType === "Percentage" ? (v * 1000000) / 10000 : v}
                             parse={v => formData.valueType === "Percentage" ? v / 100 : v}  {...rest} source="value"/>
               }
               {(formData.deductionType === 'Other' ||formData.deductionType === 'loan') &&
               <BooleanInput  {...rest} source="cash"/>
               }
           </Fragment>
       )}

       {+scope===2 && formData.deductionType==='loan' &&  <ReferenceInput {...rest}
                       source="ref"
                       onChange={ev=> form.change('name',loans.find(l=>l.id===ev.target.value).name)}
                       reference="loans"
                       filter={{filter:`employeeId eq ${formData.employeeId} and LoanStatus eq 'Standing'`}}


       >
           <SelectInput optionText="name" />
       </ReferenceInput>
       }
       {+scope<=1&&<><CheckboxGroupInput   source="appliesTo" choices = {[
           { id: "FullTime", name: 'FullTime Employees' },
           { id: "Contract", name: 'Contract Employees' },
           { id: "Casual", name: 'Casual Employees' },
           { id: 'PartTime', name: 'PartTime Employees' },
       ]} />
           <CheckboxGroupInput   source="appliesToCitizens" choices = {[
               { id: "Ethiopian", name: 'Ethiopians' },
               { id: "Non-Ethiopian", name: 'Non Ethiopians' },
           ]} />
       </>}
       <BooleanInput  source="dayBased" />
       {+scope!==3&&<DateInput clearable     source={"startDate"} label="Start date" options={{ format: 'DD/MM/YYYY', inputVariant:'filled',clearable: true  }}  />}
       {+scope===3&& <BooleanInput  source="showOriginal" />}
       {/*<DateTimeInput source="endDate" label="End time" options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }} />*/}
   </Fragment>
}

export const DeductionCreate = props =>
{
    // console.log()
   // console.log('creating deduction')
    // todo remove duplocates from earnings and expenses
  //  // const { scopeId: scopeId_string, scope } = JSON.parse(parse(props.location.search).source);
  //   const { scopeId: scopeId_string, scope } =parse(props.location.search);
  //   let  scopeId = scopeId_string ? parseInt(scopeId_string, 10) : "";
  //   if(+scope===0||+scope===3) //company id is string
  //   {
  //       scopeId = scopeId_string;
  //   }
  //
  //
  //   const redirect = scopeId ? `/${scopeResourcesEnum[+scope]}/${scopeId}/show/deductions` : "show";
  // //  console.log('creating deduction',scopeId)
  //   const scopeText = +scope===3?'Company':scopeEnum[+scope] || '';
    const {redirect,scopeText,scopeId,scopeName,scope} = getScope(props,"deductions")
    //console.log(scopeText)
    return    (

        <Create {...props}>
            <SimpleForm  initialValues={{appliesTo:['FullTime'],appliesToCitizens:['Ethiopian','Non-Ethiopian'],[`${scopeText.toLowerCase()}Id`]:scopeId,scope:scopeName,cash:false,dayBased:true}} redirect={redirect}>

                <FormDataConsumer>
                    {formDataProps => (
                        <DeductionForm {...formDataProps} scopeText={scopeText} scope={scope} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )};
