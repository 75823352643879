import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    List,
    NumberField,
    NumberInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import exporterCommon from "../import/exporterCommon";
import {parse} from "query-string";


export const CurrencyRateList= props => (
    <List sort={{field:"Date", order:'desc'}}   exporter={exporterCommon(props.resource)}  {...props}>
        <Datagrid >
            {/*<TextField source="id" />*/}
            <DateField source="date" />
            <NumberField source="usd" />
            <NumberField source="eur" />
            <NumberField source="gbp" />
            <TextField source="source" />

        </Datagrid>
    </List>
);

// export const CurrencyRateEdit = props => (
//     <Edit {...props}>
//         <SimpleForm>
//             <TextInput source="name" />
//         </SimpleForm>
//     </Edit>
// );

export const CurrencyRateCreate = props => {
    const { companyId} =parse(props.location.search);
    const redirect = companyId ? `/companies/${companyId}/show/currencyRates` : "show";
   return <Create {...props}  >
        <SimpleForm redirect={redirect}>
            <NumberInput source="usd" />
            <NumberInput source="eur" />
            <NumberInput source="gbp" />
            <TextInput defaultValue={companyId ?'Company':''} source="source" />
        </SimpleForm>
    </Create>
}

// export const CurrencyRateShow = props => (
//     <Create {...props}  >
//         <SimpleForm redirect='list' >
//             <TextField source="name" />
//         </SimpleForm>
//     </Create>
// );
