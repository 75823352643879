import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Error, Loading, Tab, TabbedShowLayout, Title, useQueryWithStore, useDataProvider} from 'react-admin';
import moment from 'moment'
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import PayrollSheetReport from "./payrolSheetReport";
import PayrollBankList from "./payrollBankList";
import PayslipList from "./payslipList";
import  MultiSelect  from "react-multi-select-component";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TaxDeclarationReport from "./incomeTaxDeclaration";
import {MultiCurrencyContext} from "../../contexts";
import PensionDeclarationReport from "./pensionDeclaration";
import Grid from "@material-ui/core/Grid";
import EmailIcon from "@material-ui/icons/MailOutline"
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import {connect, useDispatch, useStore} from "react-redux";
import PayrollJournal from "./journal/payrollJournal";
import PayrollLoan from "./payrollLoan/payrollLoan";
import SelectAllAutocomplete from "../../UIComponents/selectAllAutocomplete";
import PayrollSummeryReport from "./summery/PayrollSummeryReport";
const defaultJournalEntries = [{
    description: "",
    debit: [
        {
            accountName:"Salaries Expense",
            accountCode:"4001",
            amount: 0,
            plusColumns:['totalIncome'],
            minusColumns:['absenceDeduction'],
            customValues:[{description:"",amount:0}]
        },

    ],
    credit: [
        {
            accountName:"Salaries Payable",
            accountCode:"2001",
            amount: 0,
            plusColumns:['netPay'],
            minusColumns:[],
            customValues:[{description:"",amount:0}]
        },
        {
            accountName:"Income Tax Payable",
            accountCode:"2002",
            amount: 0,
            plusColumns:['incomeTax'],
            minusColumns:[],
            customValues:[{description:"",amount:0}]
        },

        {
            accountName:"PF Payable(Employee)",
            accountCode:"2003",
            amount: 0,
            plusColumns:['pf'],
            minusColumns:[],
            customValues:[{description:"",amount:0}]
        },
        {
            accountName:"Pension Payable(Employee)",
            accountCode:"2004",
            amount: 0,
            plusColumns:['empPension'],
            minusColumns:[],
            customValues:[{description:"",amount:0}]
        },
        {
            accountName:"Other Payroll Payable",
            accountCode:"2005",
            amount: 0,
            plusColumns:['totalIncome'],
            minusColumns:['absenceDeduction','netPay','empPension','pf','incomeTax'],
            customValues:[{description:"",amount:0}]
        },
    ]
}]


const PayrollReports = props => {

    const id  = +props.match.params.id;
    const store = useStore();
    const {templateEarnings, templateLoans, templateDeductions, templateExpenses} = props;
    const [emps,setEmps] = useState()
    // const [, forceUpdate] = useReducer(x => x + 1, 0);
   //const history = useHistory()
    const [department, setDepartment] = useState(0);
    const [costCenter, setCostCenter] = useState(0);
    const [tinStatus, setTinStatus] = useState(0);

    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [site, setSite] = useState(0);
    const [payrollStatus, setPayrollStatus] = useState('Processing');
    const payrollStates = [
        { id: 'Processing', name: 'Processing' },
        { id: 'Paid', name: 'Paid' },
        { id: 'Held', name: 'Held' },
    ]
    const [employmentType, setEmploymentType] = useState(0);
    const [emailStatus, setEmailStatus] = useState(-1);
    // const dataProvider = useDataProvider();
    const [payrollRun, setPayrollRun] = useState();
    // const [banks, setBanks] = useState([]);
    const [companyJournalEntries, setCompanyJournalEntries] = useState();
    const [filteredPayrolls, _setFilteredPayrolls] = useState();
  const [settingId,setSettingId] = useState();
    const recalculate = (journalEntries,payrollRun,filteredPayrolls=[],separateByCostCenter,accounts,selectedCostCenter) => {
       // console.log({separateByCostCenter,selectedCostCenter})
        const costCenterJE = (separateByCostCenter&&costCenters&&costCenters.map(c=>({
            ...journalEntries[0],costCenterCode:c.code,
            description: `${c.name}`

        })))||[{...journalEntries[0],costCenterCode:null}]


        function getAccountCode(costCenter,accountCode) {
         const account =  accounts&&accounts.find(a=>a.costCenter&&a.costCenter.code===costCenter && a.parrentAccount&&a.parrentAccount.code===accountCode)
         return account?account.code:selectedCostCenter===-1?accountCode+'-'+costCenter:accountCode
        }


        const costCentersJournal =  costCenterJE.map(j=>({
            ...j,
            debit :j.debit.map(dr=>({...dr,code:getAccountCode(j.costCenterCode,dr.accountCode) ,amount: filteredPayrolls.filter(p=>p.costCenterCode===j.costCenterCode).reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)})),
            credit:j.credit.map(dr=>({...dr,code:getAccountCode(j.costCenterCode,dr.accountCode),amount: filteredPayrolls.filter(p=>p.costCenterCode===j.costCenterCode).reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)})),
            isCompany :false


        }))

        const defaultJournal =  {

            debit:journalEntries[0].debit.map(dr=>({...dr,amount: filteredPayrolls.filter(p=>!p.costCenterCode).reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)})),
            credit: journalEntries[0].credit.map(dr=>({...dr,amount: filteredPayrolls.filter(p=>!p.costCenterCode).reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)})),
            isCompany: false,
            description:"Default Cost Center"}

            const totalDefaultJournal = defaultJournal.debit.reduce((sum,cv)=>sum+cv.amount,0)

        const companyJournal =  {

            debit:journalEntries[0].debit.map(dr=>({...dr,code:'',amount: filteredPayrolls.reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)})),
            credit: journalEntries[0].credit.map(dr=>({...dr,code:'',amount: filteredPayrolls.reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)})),
             isCompany:true,
            description:props.companyName}
        const totalCompanyJournal = companyJournal.debit.reduce((sum,cv)=>sum+cv.amount,0)

       const payrollrunCopy = {...payrollRun};

        // const y =  journalEntries.map(j=>{
        //     j.debit = j.debit.map(dr=>({...dr,amount: filteredPayrolls.filter(p=>p.costCenterCode==="SALES").reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)}))
        //     j.credit= j.credit.map(dr=>({...dr,amount: filteredPayrolls.filter(p=>p.costCenterCode==="SALES").reduce((sum,cv)=>sum+dr.plusColumns.reduce((tot,c)=>tot+cv[c],0)-dr.minusColumns.reduce((tot,c)=>tot+cv[c],0)+dr.customValues.reduce((tot,c)=>tot+c.amount,0),0)}))
        //
        //
        //     return j
        // })
        // x.pus

        payrollrunCopy.journalEntries =  [...(separateByCostCenter ? costCentersJournal : []),...(totalDefaultJournal>0&&totalCompanyJournal!==totalDefaultJournal ? [defaultJournal] : []),companyJournal]
        setPayrollRun(payrollrunCopy)
       // console.log({je:x})
    }


    const { loadedSetting} = useQueryWithStore({
            type: 'getList',
            resource: 'payrollReportSettings',
        },{
            onSuccess: (res) =>{

                //  console.log(res)
                if(res.data[0])
                {
                   // console.log("hihi",res.data[0].journalEntries)
                    if(res.data[0].journalEntries && res.data[0].journalEntries.length){
                      setCompanyJournalEntries(res.data[0].journalEntries)

                    }
                    setSettingId(res.data[0].id)
                    // setShowFooter(!!res.data[0].showFooter)
                    // if(res.data[0].bankFooters && res.data[0].bankFooters.length) setFooterItems(res.data[0].bankFooters)
                    // setColumnsId(res.data[0].id)
                    // // setColumnsId(res.data[0].id)
                    // if(res.data[0].bankColumns&&res.data[0].bankColumns.length) {
                    //     setColumns(res.data[0].bankColumns)
                    // }
                }
            }
        }
    );





   function  setFilteredPayrolls(filteredPayrolls) {

           filteredPayrolls && _setFilteredPayrolls(filteredPayrolls.map((f, index) => {
                   f.sn = index + 1;
                   return f
               })
           )



   }
    const [sort,setSort] = useState({ field: 'fullName', order: 'DESC' });
    const handelSort = (field,data=filteredPayrolls)=> {
       // console.log(field)
        let order = 'ASC';
        if(field===sort.field)
        {
            order = sort.order==='DESC'?'ASC':'DESC';
            // setSort({ field, order: sort.order==='DESC'?'ASC':'DESC' })
        }

        setSort({ field, order})


        setFilteredPayrolls(_.orderBy(data, [item => isNaN(item[field])?item[field].toString().toLowerCase():item[field]], [order.toLowerCase()]));

    }



    // const [loading, setLoading] = useState(true);
   // const [error, setError] = useState();
    // useEffect(() => {
    //     dataProvider.getOne('payrollRuns', { id: id, expand:'payrolls($expand=payrollFixedEarnings ,payrollDeductions,payrollFixedDeductions,bonuses,employee($expand=position($select=name;$expand=department($select=name))))' })
    //         .then(({ data }) => {
    //             data.payrolls.map(p=>{
    //                 p.department = p.employee.position.department.name
    //                 p.position = p.employee.position.name
    //               })
    //             console.log(data)
    //             setPayrollRun(data);
    //             setLoading(false);
    //         })
    //
    //         .catch(error => {
    //             setError(error);
    //             setLoading(false);
    //         })
    // }, []);
    // const { data:templateEarnings } = useQueryWithStore({
    //     type: 'getList',
    //     resource: 'earnings',
    //     payload: {filter:{filter:"Scope eq 'Template'"},pagination:{},sort:{}}
    // });
  //  console.log(templateEarnings)

    //if (!templateEarnings) return null;

    const dispatch = useDispatch();
    const loadBanks =  () =>  dataProvider.getList('companyBanks', {filter: {expand: `bank`}}).then(res=>{

      //  setBanks(res.data)
     //   console.log(res.data)
        return res.data
    })



    useEffect(() => {

       if(!templateEarnings || templateEarnings.length ===0)
       {
           dispatch({type: 'LOAD_TEMPLATES'})
       }

        dispatch({type: 'LOAD_COMPANY_SETTINGS'})


        //loadBanks().catch(console.error);


    }, [])



    const dataProvider = useDataProvider();

    const getEmployees = () => new Promise(resolve => {
        if(emps) resolve(emps)

        else
            dataProvider.getList('employees', {filter:{expand:`accounts`}}).then(res => {
                setEmps(res.data)
                resolve(res.data)
            })

    })




    const getPayrollSettings = () => new Promise(resolve => {
        if(companyJournalEntries) resolve(companyJournalEntries)
        else {
            dataProvider.getList('payrollReportSettings',{filter:{},pagination:{},sort:{field:'modifiedDate',order:'DESC'}}).then(res => resolve(res.data))
        }
    })




    const { loaded, error } = useQueryWithStore({
        type: 'getOne',
        resource: 'payrollRuns',
        payload: { id: id, expand:`payrolls($orderby=fullName; $expand=payrollFixedEarnings ,payrollFixedExpenses, payrollDeductions,payrollFixedDeductions,bonuses,overtimes($expand=type))`}//,employee($select=OrgID,Id,Name ,FatherName ,GFatherName,OutstandingLoan ,RemainingLeaveDays,BasicSalary ,BankAccountNumber,DefaultCurrency,Fullname, Gender ,address,TinNo,PensionNo,BankBranch ;$expand=leaves($filter=TypeId eq 1 and Year eq ${new Date().getFullYear()} )))` }

        // payload: { id: id, expand:`currencyRate,payrolls($orderby=fullName; $expand=payrollFixedEarnings ,payrollDeductions,payrollFixedDeductions,bonuses,employee($select=OrgID,Id,Name ,FatherName ,GFatherName,OutstandingLoan ,RemainingLeaveDays,BasicSalary ,BankAccountNumber,DefaultCurrency,Fullname, Gender ,address,TinNo,PensionNo,BankBranch ;$expand=leaves($filter=TypeId eq 1 and Year eq ${new Date().getFullYear()} ),position($select=name;$expand=department($select=name))))` }
    },{
        onSuccess: res=>{
        //  const customEarnings =   templateEarnings.map(e=>({name:e.name)

            //  console.log(templateEarnings)
      //    if(!emps) refresh()
            if(res.data && res.data.payrolls) {
              //  console.log("fetching aaaa")
                const states = store.getState()
                const templates = (states&&states.payrollStatus)||{}
                const companySettings = states&&states.setting&&states.setting.companySettings

                // console.log("x",companySettings,templates)
                Promise.all([getEmployees(), getPayrollSettings(),loadBanks()]).then(data => initPayroll(res, data[0], data[1][0] && data[1][0].journalEntries && data[1][0].journalEntries.length ? data[1][0].journalEntries : defaultJournalEntries, templates, data[2],companySettings))
            }           //return initPayroll(res,emps)

          //  return res
        }
    });
  //  console.log(payrollRun)


    const initPayroll = (res,emps,journalEntries,templates,banks,companySettings) => {


  // console.log(journalEntries)


        const {templateEarnings, templateLoans, templateDeductions, templateExpenses} = templates;
        //  console.log('templates',templates)
         // console.log({banks})


        res.data.payrolls.map((p,index)=>{

            // if(!p.employee){
            //     p.employee= {}
            // }
            p.employee = (emps && emps.find(e=>e.id === p.employeeId)) || {}
            // console.log(banks)
            // banks.then(ba=>{
            //     p.companyBank= ba.find(b=>b.id===p.employee.companyBankId)
            // })
            // banks.then(ba=>console.log(ba))
            p.companyBank= banks.find(b=>b.id===p.employee.companyBankId)
            p.tinNo = p.tinNo || p.employee.tinNo
            p.nationalID = p.employee.nationalID
            p.taxRegion =   p.employee.taxRegion
            p.grade =  p.employee.grade
            p.subFunction = p.employee.subFunction
            p.function = p.employee.function


           // p.employee.sendPayslipVia = p.employee.sendPayslipVia && p.employee.sendPayslipVia.length? p.employee.sendPayslipVia : ['Publish', 'EmailLink']
            if(new Date(p.employee.startDate)<new Date('1/1/1900')||moment(p.employee.startDate).format('YYYY-MM-DD')==='2015-01-01'){
                p.employee.startDate=null
            }
            p.OTTotalPayHours = 0;
            if(p.overtimes && p.overtimes.length)
            {
                p.OTTotalPayHours = p.overtimes.reduce((sum,cv)=>sum + (cv.type.rate*cv.hrs), 0);
            }

            p.otRest = p.overtimes.filter(o=>o.type.name.toLowerCase()==='sunday'||o.type.name.toLowerCase()==='rest').reduce((sum,cv)=>sum + (cv.hrs), 0);
            p.otNormal = p.overtimes.filter(o=>o.type.name.toLowerCase()==='after work').reduce((sum,cv)=>sum + (cv.hrs), 0);
            p.otHollyDay = p.overtimes.filter(o=>o.type.name.toLowerCase()==='hollyday').reduce((sum,cv)=>sum + (cv.hrs), 0);
            p.otNight = p.overtimes.filter(o=>o.type.name.toLowerCase()==='night').reduce((sum,cv)=>sum + (cv.hrs), 0);
            p.otOther = p.overtimes.filter(o=>o.type.name.toLowerCase()==='other').reduce((sum,cv)=>sum + (cv.hrs), 0);

            p.missedHours = Math.max((p.monthlyHours||0)-(p.hoursWorked||0),0)


            p._fullName = p.fullName;
            p.status = p.status || 'Processing';
            if(p.employmentType === "Casual" ) {
                p._fullName = `${p.fullName} -C- ${p.basicSalary} * ${p.daysWorked} days`
            }
            // console.log(templateEarnings)
            if(templateEarnings && templateEarnings.length)
            {
                const commonTypes = templateEarnings.map(t=>t.id.toString())
                // p.commonEarnings = templateEarnings.map(e=>{
                //      return {
                //          name: e.name,
                //          value: p.payrollFixedEarnings.reduce((sum,cv)=>{
                //              if(cv.earningType===e.id.toString()) {
                //                  return sum+cv.value;
                //              }
                //              return sum
                //          },0)
                //      }
                //
                //  })
                templateEarnings.map(e=>{


                        p[e.name.replace(/\s+/g, '').toLowerCase()]=p.payrollFixedEarnings.reduce((sum,cv)=>{
                            if(cv.earningType===e.id.toString() || cv.templateId==e.id ) {
                                return sum+cv.value;
                            }
                            return sum
                        },0)

                    if(e.showOriginal) {
                        p['full' + e.name.replace(/\s+/g, '').toLowerCase()] = p.payrollFixedEarnings.reduce((sum, cv) => {
                            if (cv.earningType === e.id.toString() || cv.templateId == e.id) {
                                return sum + cv.originalValue;
                            }
                            return sum
                        }, 0)
                        p[e.name.replace(/\s+/g, '').toLowerCase()+'difference'] = p.payrollFixedEarnings.reduce((sum, cv) => {
                            if (cv.earningType === e.id.toString() || cv.templateId == e.id) {
                                return sum + cv.originalValue;
                            }
                            return sum
                        }, 0)
                    }

                    }

                )

                p.commonEarnings = p.payrollFixedEarnings.reduce((sum,cv)=>{
                    if(commonTypes.includes(cv.earningType) || (cv.templateId && commonTypes.includes(cv.templateId.toString()))) {
                        return sum+cv.value;
                    }
                    return sum
                },0)

                p.otherEarnings = p.additionalFixedEarnings - p.commonEarnings
            }

            if(templateDeductions && templateDeductions.length)
            {
                const commonTypes = templateDeductions.map(t=>t.id.toString())
                // p.commonEarnings = templateEarnings.map(e=>{
                //      return {
                //          name: e.name,
                //          value: p.payrollFixedEarnings.reduce((sum,cv)=>{
                //              if(cv.earningType===e.id.toString()) {
                //                  return sum+cv.value;
                //              }
                //              return sum
                //          },0)
                //      }
                //
                //  })
                templateDeductions.map(e=>{


                        p[e.name.replace(/\s+/g, '').toLowerCase()]=p.payrollFixedDeductions.reduce((sum,cv)=>{
                            if(cv.deductionType===e.id.toString() || cv.templateId==e.id ) {
                                return sum+cv.value;
                            }
                            return sum
                        },0)

                    if(e.showOriginal) {
                        p['full' + e.name.replace(/\s+/g, '').toLowerCase()] = p.payrollFixedDeductions.reduce((sum, cv) => {
                            if (cv.deductionType === e.id.toString() || cv.templateId == e.id) {
                                return sum + cv.originalValue;
                            }
                            return sum
                        }, 0)
                        p[e.name.replace(/\s+/g, '').toLowerCase()+'difference'] = p.payrollFixedDeductions.reduce((sum, cv) => {
                            if (cv.deductionType === e.id.toString() || cv.templateId == e.id) {
                                return sum + cv.originalValue;
                            }
                            return sum
                        }, 0)
                    }

                    }

                )

                p.commonDeductions = p.payrollFixedDeductions.reduce((sum,cv)=>{
                    if(commonTypes.includes(cv.deductionType) || (cv.templateId && commonTypes.includes(cv.templateId.toString()))) {
                        return sum+cv.value;
                    }
                    return sum
                },0)

                p.otherDeductions = p.fixedDeductionsTotals - p.commonDeductions - p.empPension - p.pf
            }

            if(templateExpenses && templateExpenses.length)
            {
                const commonTypes = templateExpenses.map(t=>t.id.toString())
                // p.commonEarnings = templateEarnings.map(e=>{
                //      return {
                //          name: e.name,
                //          value: p.payrollFixedEarnings.reduce((sum,cv)=>{
                //              if(cv.earningType===e.id.toString()) {
                //                  return sum+cv.value;
                //              }
                //              return sum
                //          },0)
                //      }
                //
                //  })
                templateExpenses.map(e=>{


                        p[e.name.replace(/\s+/g, '').toLowerCase()]=p.payrollFixedExpenses.reduce((sum,cv)=>{
                            if(cv.type===e.id.toString() || cv.templateId==e.id ) {
                                return sum+cv.value;
                            }
                            return sum
                        },0)

                    if(e.showOriginal) {
                        p['full' + e.name.replace(/\s+/g, '').toLowerCase()] = p.payrollFixedExpenses.reduce((sum, cv) => {
                            if (cv.type === e.id.toString() || cv.templateId == e.id) {
                                return sum + cv.originalValue;
                            }
                            return sum
                        }, 0)
                        p[e.name.replace(/\s+/g, '').toLowerCase()+'difference'] = p.payrollFixedExpenses.reduce((sum, cv) => {
                            if (cv.type === e.id.toString() || cv.templateId == e.id) {
                                return sum + cv.originalValue;
                            }
                            return sum
                        }, 0)
                    }

                    }

                )

                p.commonExpenses = p.payrollFixedExpenses.reduce((sum,cv)=>{
                    if(commonTypes.includes(cv.type) || (cv.templateId && commonTypes.includes(cv.templateId.toString()))) {
                        return sum+cv.value;
                    }
                    return sum
                },0)

                p.otherExpenses = p.fixedExpensesTotals - p.commonExpenses - p.companyPension - p.companyPf
            }

            if(templateLoans)
            {
                const commonTypes = templateLoans.filter(t=>t.name).map(t=>t.name.replace(/\s+/g, '').toLowerCase())
                // p.commonEarnings = templateEarnings.map(e=>{
                //      return {
                //          name: e.name,
                //          value: p.payrollFixedEarnings.reduce((sum,cv)=>{
                //              if(cv.earningType===e.id.toString()) {
                //                  return sum+cv.value;
                //              }
                //              return sum
                //          },0)
                //      }
                //
                //  })
                templateLoans.map(e=>{


                        p[e.name.replace(/\s+/g, '').toLowerCase()]=p.payrollDeductions.reduce((sum,cv)=>{
                            if(cv.cash && cv.name && cv.name.replace(/\s+/g, '').toLowerCase()===e.name.replace(/\s+/g, '').toLowerCase() ) {
                                return sum+cv.value;
                            }
                            return sum
                        },0)

                        p[e.name.replace(/\s+/g, '').toLowerCase()+'Remaining']=p.payrollDeductions.reduce((sum,cv)=>{
                            if(cv.cash && cv.name && cv.name.replace(/\s+/g, '').toLowerCase()===e.name.replace(/\s+/g, '').toLowerCase() ) {
                                return sum+(cv.remaining||0);
                            }
                            return sum
                        },0)

                    }

                )

                p.commonLoans = p.payrollDeductions.reduce((sum,cv)=>{
                    if(cv.cash &&cv.name && commonTypes.includes(cv.name.replace(/\s+/g, '').toLowerCase())) {
                        return sum+cv.value;
                    }
                    return sum
                },0)
                p.otherSettlements = p.payrollDeductionTotalCash- p.commonLoans
            }







            //  p.sn = index+1
            //p.orgID = p.orgID ///"111"// p.employee.orgID
            //  p.department = p.employee.position.department.name
            //  p.position = p.employee.position.name
            p.employmentType =  p.employmentType || p.employee.employmentType
            if(p.employmentType === 'Casual')
            {
                p.wadge =  p.basicSalary;
                p.basicSalary = p.earnedSalary;

            }


            p.totalTransportAllowance = p.payrollFixedEarnings.reduce((sum,cv)=>{
                if(cv.earningType&&cv.earningType.replace(/\s+/g, '').toLowerCase()==='transportallowance') {
                    return sum+cv.value;
                }
                return sum
            },0)


            // p.taxableTransportAllowance= p.payrollFixedEarnings.reduce((sum,cv)=>{
            //     if(cv.earningType.replace(/\s+/g, '').toLowerCase()==='transportallowance') {
            //         const amount = cv.value >(cv.limitByValue||0)?cv.value-(cv.limitByValue||0):0;
            //         return sum+amount
            //     }
            //     return sum
            // },0)
            //  const exemptedAllowance =  _.maxBy(p.payrollFixedEarnings.filter(cv=>cv.earningType.replace(/\s+/g, '').toLowerCase().includes('transportallowance')),'limitByValue')

          const transportAllowances = p.payrollFixedEarnings.filter(cv=>cv.earningType&&cv.earningType.replace(/\s+/g, '').toLowerCase().includes('transportallowance'))



            let totalTransportAllowance = transportAllowances.reduce((sum,cv)=>sum+cv.value,0)
            if((p.additionalFixedEarnings -  totalTransportAllowance)<0)
            {
                totalTransportAllowance +=(p.additionalFixedEarnings -  totalTransportAllowance)
            }
            // console.log("tt",totalTransportAllowance)
            const limit = (_.maxBy(transportAllowances.filter(t=>t.taxOption==='TaxableWithLimits'),'limitByValue')||{limitByValue:0}).limitByValue;
// console.log("limit",limit)

            p.taxableTransportAllowance = Math.max(totalTransportAllowance- limit,0)||0;

            p.declarationTransportAllowance = totalTransportAllowance - p.additionalEarningsAbsentDeduction;

            const overtimeAllowance =  p.payrollFixedEarnings.filter(cv=>cv.earningType&&cv.earningType.replace(/\s+/g, '').toLowerCase().includes('overtime')).reduce((sum,cv)=>sum+cv.value,0)


            // const taxableExtraEarnings =  _.sumBy(p.bonuses.filter(b=>b.taxOption&&b.taxOption.toLowerCase().includes('taxable')),'value');

            // const taxableEarnings = p.payrollFixedEarnings.filter
           // p.additionalFixedEarnings + (taxableExtraEarnings||0) -  totalTransportAllowance - overtimeAllowance
            p.totalOvertimeAllowance = p.overtimeTotal + overtimeAllowance

            // const totalTransportAllowance = p.payrollFixedEarnings.filter(cv=>cv.earningType.replace(/\s+/g, '').toLowerCase().includes('transportallowance')).reduce((sum,cv)=>sum+cv.value,0)
            // console.log("tt",totalTransportAllowance)






            //   p.taxableTransportAllowance = Math.max(totalTransportAllowance- 2200,0);

            p.costSharing= p.payrollFixedDeductions.reduce((sum,cv)=>{
                if((cv.deductionType&&cv.deductionType.toLowerCase().replace(/\s+/g, '')==='costsharing')||cv.name.toLowerCase().replace(/\s+/g, '').includes('costshar')) {
                    return sum+cv.value;
                }
                return sum
            },0)



            p.fixedDeductionsTotalsAndIncomeTax = p.fixedDeductionsTotals + p.incomeTax
            p.paybackTax = p.payrollDeductions.reduce((sum,cv)=>{
                if(cv.type==='tax') {
                    return sum+cv.value;
                }
                return sum
            },0)

            p.nonTaxDeductions = p.payrollDeductionTotalCash - p.paybackTax

            p.fixedGrossIncome = p.basicSalary+  p.additionalFixedEarnings
            p.serviceCharge  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name||'').replace(/\s+/g, '').toLowerCase()==="servicecharge").reduce((sum,cv)=>sum+cv.value,0)
            p.serviceChargeDeduction  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name||'').replace(/\s+/g, '').toLowerCase()==="servicechargededuction").reduce((sum,cv)=>sum+cv.value,0)
            p.otherEarningDeduction  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name||'').replace(/\s+/g, '').toLowerCase()==="otherdeduction").reduce((sum,cv)=>sum+cv.value,0)
            p.backPay  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name||'').replace(/\s+/g, '').toLowerCase()==="backpay").reduce((sum,cv)=>sum+cv.value,0)
            p.annualBonus = p.bonuses && p.bonuses.filter(b=>(b.type||b.name||'').replace(/\s+/g, '').toLowerCase()==="bonus").reduce((sum,cv)=>sum+cv.value,0)
            p.netAnnualBonus = p.annualBonus - p.additionalIncomeTax
            p.shiftAllowance  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name||'').replace(/\s+/g, '').toLowerCase()==="shiftallowance").reduce((sum,cv)=>sum+cv.value,0)
            p.shiftAllowanceHours  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name||'').replace(/\s+/g, '').toLowerCase()==="shiftallowance").reduce((sum,cv)=>sum+(cv.extraData && cv.extraData.shiftHour)||0,0)

            p.onCallAllowance  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name||'').replace(/\s+/g, '').toLowerCase()==="oncallallowance").reduce((sum,cv)=>sum+cv.value,0)
            p.onCallAllowanceHours  = p.bonuses && p.bonuses.filter(b=>(b.type||b.name||'').replace(/\s+/g, '').toLowerCase()==="oncallallowance").reduce((sum,cv)=>sum+(cv.extraData && cv.extraData.shiftHour)||0,0)

            p.shiftAllowance10 = p.bonuses && p.bonuses.filter(b=>(b.name||'').replace(/\s+/g, '').toLowerCase()==="10%shiftallowance").reduce((sum,cv)=>sum+(cv.extraData && cv.extraData.shiftHour)||0,0)
            p.shiftAllowance15 = p.bonuses && p.bonuses.filter(b=>(b.name||'').replace(/\s+/g, '').toLowerCase()==="15%shiftallowance").reduce((sum,cv)=>sum+(cv.extraData && cv.extraData.shiftHour)||0,0)
            p.shiftAllowance20 = p.bonuses && p.bonuses.filter(b=>(b.name||'').replace(/\s+/g, '').toLowerCase()==="20%shiftallowance").reduce((sum,cv)=>sum+(cv.extraData && cv.extraData.shiftHour)||0,0)
            p.shiftAllowance25 = p.bonuses && p.bonuses.filter(b=>(b.name||'').replace(/\s+/g, '').toLowerCase()==="25%shiftallowance").reduce((sum,cv)=>sum+(cv.extraData && cv.extraData.shiftHour)||0,0)

            p.onCallAllowance10 = p.bonuses && p.bonuses.filter(b=>(b.name||'').replace(/\s+/g, '').toLowerCase()==="10%oncallallowance").reduce((sum,cv)=>sum+(cv.extraData && cv.extraData.shiftHour)||0,0)
            p.onCallAllowance15 = p.bonuses && p.bonuses.filter(b=>(b.name||'').replace(/\s+/g, '').toLowerCase()==="15%oncallallowance").reduce((sum,cv)=>sum+(cv.extraData && cv.extraData.shiftHour)||0,0)



            p.grossIncome = p.earnedSalary + p.additionalEarningsTotal - p.additionalEarningsAbsentDeduction
            p.totalPension = p.companyPension + p.empPension
            p.totalPf = p.companyPf + p.pf

            p.commission = p.bonuses.filter(b=>(b.type&&b.type.replace(/\s+/g, '').toLowerCase().includes('commission'))||(b.name&&b.name.replace(/\s+/g, '').toLowerCase()==='commission')).reduce((sum,cv)=>sum+cv.value,0)
            p.otherExtraEarnings = p.bonuses.filter(b=>(b.type&&b.type.replace(/\s+/g, '').toLowerCase().includes('other'))||(b.name&&b.name.replace(/\s+/g, '').toLowerCase()==='other')).reduce((sum,cv)=>sum+cv.value,0)



             p.bank= p.bank || (p.employee && p.employee.bank)
             p.bankAccountNumber= p.bankAccountNumber || (p.employee && p.employee.bankAccountNumber)
             p.bankAccountHolderName= p.bankAccountHolderName || (p.employee && p.employee.bankAccountHolderName)||p.fullName

            p.formatedStartDate = p.employee.startDate ? moment(p.employee.startDate).format("DD/MM/YYYY"):''
            p.bonusFactor= (p.bonusData&&p.bonusData.bonusFactor)||0

            p.monthsInBonusYear =(p.bonusData&&p.bonusData.monthsInBonusYear)||0
            p.swiftCode = p.companyBank && p.companyBank.bank && p.companyBank.bank.swiftCode;
            p.basicSalaryDifference= p.fullBasicSalary - p.basicSalary
            p.empPensionFull = (p.payrollFixedDeductions.find(d=>d.deductionType==='EmpPension')||{originalValue:0}).originalValue
            p.empPensionDifference = p.empPensionFull - p.empPension
            p.companyPensionFull = (p.payrollFixedExpenses.find(d=>d.type==='CompanyPension')||{originalValue:0}).originalValue
            p.companyPensionDifference = p.companyPensionFull - p.companyPension
            p.otherEarningsThanTransport =  p.taxableEarnings + p.taxableBonuses + p.annualBonus -  p.taxableTransportAllowance - overtimeAllowance
            if( p.annualBonus >0) {
                p.taxableIncome += p.annualBonus
            }

            // console.log({companySettings})
            if(companySettings&&companySettings.enableExtraColumns&&(companySettings.enableExtraColumns.value==='yes'||companySettings.enableExtraColumns.value==='true')) {
                p.gender = p.employee.gender
                p.email = p.emailAddress || (p.employee.address && p.employee.address.email)
                p.pensionNo = p.employee.pensionNo
                p.dateOfBirth = (p.employee.dateOfBirth && (new Date(p.employee.dateOfBirth) > new Date(1900)) && moment(p.employee.dateOfBirth).format('DD/MM/YYYY'))||''
                p.citizenship = p.employee.citizenship

            }

            p.payrollDeductions.map(c=>{
                c.templateType = (templateLoans.find(l=>l.id===c.templateId)||{}).type
                return c
            })

            // p.gender = p.employee.gender
            // p.email = p.emailAddress || (p.employee.address && p.employee.address.email)



                // p.overtimeTotalAll = p.overtimeTotal+p.additionalFixedEarnings
        })

        //setFilteredPayrolls(res.data.payrolls)
        handelSort(sort.field,res.data.payrolls.filter(p=>p.status === payrollStatus))
      //  console.log(journalEntries)
       // setPayrollRun(res.data)
       filterPayrolls({payrolls:res.data.payrolls,journalEntries,payrollRun1:res.data})
        // recalculate(res.data.journalEntries&&res.data.journalEntries.length?res.data.journalEntries:journalEntries,res.data,filteredPayrolls)
        // if(res.data.site||res.data.employmentType||res.data.selectedStatus||res.costCenter) {
        //     setSite(res.data.site)
        //     setPayrollStatus(res.data.selectedStatus)
        //     setEmploymentType(res.data.employmentType)
        //     setCostCenter(res.data.costCenter)
        //
        //     // setFilteredPayrolls(res.data.payrolls.filter(p=>(!res.data.site||p.siteName===res.data.site)&&(!department||p.departmentName===department)&&(!res.data.employmentType||p.employmentType===res.data.employmentType)&&(!res.data.selectedStatus||p.status===res.data.selectedStatus)&&(!res.data.costCenter||p.costCenterCode===res.data.costCenter)))
        //
        // }
        return res;
    }



    const { data: departments } = useQueryWithStore({
        type: 'getList',
        resource: 'departments',
        payload: {filter:{},pagination:{},sort:{}}
    });
    const { data: costCenters} = useQueryWithStore({
        type: 'getList',
        resource: 'costCenters',
        payload: {filter:{},pagination:{},sort:{}}
    });

    const { data: sites} = useQueryWithStore({
        type: 'getList',
        resource: 'sites',
        payload: {filter:{},pagination:{},sort:{}}
    });

    const { data: accounts} = useQueryWithStore({
        type: 'getList',
        resource: 'accounts',
        payload: {filter:{expand:'ParrentAccount,CostCenter'},pagination:{},sort:{}}
    });

    // const {data:banks}=  useQueryWithStore({
    //     type: 'getList',
    //     resource: 'companyBanks',
    //     payload: { filter:{expand:"bank"} }
    //
    // }  )


    const employmentTypes = ["FullTime", "Contract", "Casual", "PartTime"]
    // const { data: employees} = useQueryWithStore({
    //     type: 'getList',
    //     resource: 'employees',
    //     payload: {filter:{filter:'active eq true'},pagination:{},sort:{}}
    // });
    const employees =  payrollRun&&payrollRun.payrolls.map(p=>({id:p.employeeId,fullName:p.fullName, orgID:p.orgID||p.employee.orgID}))

    const { data: company } = useQueryWithStore({
        type: 'getOne',
        resource: 'companies',
        payload: {id:localStorage.getItem('companyId')}
    });
  function refresh() {
     // console.log("forceUpdate")
     // forceUpdate()
      props.history.push("/");
      props.history.goBack();

    }

    if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if (!filteredPayrolls)  return null;

   // handelSort(sort.field)

    function separateByCostCenter(payrolls) {
        const newPayrolls = []
        const removePayrolls = []

        const templateCols = {}

        templateEarnings.forEach(e=> {
            templateCols[e.name.replace(/\s+/g, '').toLowerCase()]=0
        } )
        templateDeductions.forEach(e=> {
            templateCols[e.name.replace(/\s+/g, '').toLowerCase()]=0
        } )
        templateLoans.forEach(e=> {
            templateCols[e.name.replace(/\s+/g, '').toLowerCase()]=0
        } )
        templateExpenses.forEach(e=> {
            templateCols[e.name.replace(/\s+/g, '').toLowerCase()]=0
        } )

        payrolls.forEach(p=>{
            const costCenterTotal = p.costCenterItems.filter(cs=>cs.costCenterCode!==p.costCenterCode).reduce((sum,cv)=>sum+cv.totalIncome,0)
            const costCenterTax = p.costCenterItems.filter(cs=>cs.costCenterCode!==p.costCenterCode).reduce((sum,cv)=>sum+cv.incomeTax,0)
            if(p.costCenterItems&&p.costCenterItems.length) {
                p.costCenterItems.forEach(csi=>{
                    if(csi.costCenterCode!==p.costCenterCode) {




                      newPayrolls.push({

                          ..._.pick(p,[
                              'employmentType','name','positionName','departmentName',
                              'siteName','jobLevelName','bank','bankBranch','bankAccountNumber',
                              'pensionNo','tinNo','status', '_fullName', 'fullName'

                          ]),
                          basicSalary:costCenterTotal,
                          overtimes: [],
                          nonTaxableIncome: 0,

                          bonuses: [],
                          fixedGrossIncome:0,
                          OTTotalPayHours:0,
                          overtimeTotal:0,
                          serviceCharge:0,
                          serviceChargeDeduction:0,
                          otherEarningDeduction:0,
                          basicAbsentDeduction:0,
                          additionalEarningsAbsentDeduction:0,
                          absenceDeduction:0,
                          grossIncome:0,
                          empPension:0,
                          pf:0,
                          companyPension:0,
                          companyPf:0,
                          totalPf:0,
                          fixedDeductionsTotalsAndIncomeTax:csi.incomeTax,
                          totalDeduction:csi.incomeTax,
                          payrollDeductionTotalCash:0,
                          outstandingLoan:0,
                          additionalFixedEarningsOriginal: 0,
                          additionalFixedEarnings: 0,
                          fixedDeductionsTotals: 0,
                          fixedExpensesTotals: 0,
                          payrollFixedDeductions: [],
                          payrollFixedExpenses: [],
                          payrollFixedEarnings: [],
                          payrollDeductions:  [],
                          totalWorkDays:0, //get from database
                          absentDays: 0,
                          leaveDays:0,
                          otherDeductions: 0,
                          otherEarnings:0,
                          otherExpenses:0,
                          otherSettlements:0,
                          bonusesTotal:0,

                          ...templateCols,
                          ...csi})
                    }
                    else {
                        removePayrolls.push(p.id)
                        newPayrolls.push({
                            ...p,...csi,
                            additionalFixedEarnings : p.additionalFixedEarnings- costCenterTotal,
                            totalDeduction: p.totalDeduction - costCenterTax,
                            payrollFixedEarnings : p.payrollFixedEarnings.filter(e=>!e.costCenterCode||e.costCenterCode===p.costCenterCode),
                            fixedDeductionsTotalsAndIncomeTax: p.fixedDeductionsTotalsAndIncomeTax - costCenterTax,// - p.costCenterItems.filter(cs=>cs.costCenterCode!==p.costCenterCode).reduce((sum,cv)=>sum+cv.incomeTax,0)

                        })
                            // p.totalIncome = csi.totalIncome
                            // p.costCenterCode = csi.costCenterCode
                            // p.taxableIncome = csi.taxableIncome
                            // p.incomeTax= csi.incomeTax
                            // p.netPay=  csi.netPay
                            // p.cash=  csi.cash
                            // p.additionalFixedEarnings =  p.additionalFixedEarnings- costCenterTotal
                            // p.otherEarnings = p.otherEarnings - costCenterTotal
                            // p.totalDeduction = p.totalDeduction - costCenterTax
                            // p.payrollFixedEarnings = p.payrollFixedEarnings.filter(e=>!e.costCenterCode||e.costCenterCode===p.costCenterCode)
                            // p.fixedDeductionsTotalsAndIncomeTax = p.fixedDeductionsTotalsAndIncomeTax - costCenterTax// - p.costCenterItems.filter(cs=>cs.costCenterCode!==p.costCenterCode).reduce((sum,cv)=>sum+cv.incomeTax,0)
                    }
                })
            }
        })
        // console.log({newPayrolls})
        return payrolls.filter(p=>!removePayrolls.includes(p.id)).concat(newPayrolls)
    }

    function onSeparateByCostCenter(val=-1) {
        setCostCenter(val)
        filterPayrolls({value:val,field:'costCenter'})
    }

    function filterPayrolls({payrollRun1=payrollRun,value,field,journalEntries}) {

      const toBeFiltered =   field==='costCenter'&&value?separateByCostCenter(payrollRun1.payrolls):payrollRun1.payrolls
const checkTin = (v,p)=>(v==='Without Tin'?(!p.tinNo || p.tinNo.length === 0):(p.tinNo && p.tinNo.length))

       const fPayrolls = toBeFiltered.filter(p=>

           (field==='site'?!value||p.siteName===value:!site||p.siteName===site)&&
           (field==='employmentType'?!value||p.employmentType===value:!employmentType||p.employmentType===employmentType)&&
           (field==='department'?!value||p.departmentName===value:!department||p.departmentName===department)&&
           (field==='payrollStatus'?!value||p.status===value:!payrollStatus||p.status===payrollStatus)&&
           (field==='costCenter'?!value||value<0||p.costCenterCode===value:!costCenter||p.costCenterCode===costCenter)&&
           (field==='tinStatus'?!value||checkTin(value,p):!tinStatus||checkTin(tinStatus,p))&&
           (field==='employeeIds'?!value||!value.length||value.some(v=>v.value===p.employeeId):!selectedEmployees||!selectedEmployees.length||selectedEmployees.some(v=>v.value===p.employeeId))
       )
        setFilteredPayrolls(
fPayrolls
        )
       recalculate(payrollRun1.journalEntries&&payrollRun1.journalEntries.length?payrollRun1.journalEntries:journalEntries,payrollRun1,fPayrolls,value===-1,accounts,field==='costCenter'&&value)


        return fPayrolls

    }



    // if (loading) return <Loading />;
    // if (error) return <Error />;
     if (!payrollRun) return null;





  //   console.log(payrollRun)
    return (
        <MultiCurrencyContext.Provider value={payrollRun.usedCurrencyRates}>
        <Card>
            <Title title="Payroll Reports" />
            <CardContent>
                <Grid container>
                    <Grid item>
                <Select
                    value={department}
                    onChange={ev=>{
                        setDepartment(ev.target.value)
                        filterPayrolls({value:ev.target.value,field:'department'})
                       // setFilteredPayrolls(payrollRun.payrolls.filter(p=>(!site||p.siteName===site)&&(!employmentType||p.employmentType===employmentType)&&(ev.target.value===0||p.departmentName===ev.target.value)&&(!payrollStatus||p.status===payrollStatus)&&(!costCenter||p.costCenterCode===costCenter)))
                       // payrollRun.filteredPayrolls=filteredPayrolls
                     //   props.onChange({type:ev.target.value,hrs})
                    }}
                >
                    <MenuItem key={0} value={0}>All Departments</MenuItem>
                    {departments&&departments.map(ot=><MenuItem key={ot.id} value={ot.name}>{ot.name}</MenuItem>)}
                </Select>
                    </Grid>
                    <Grid item>
                        <Select
                            value={costCenter}
                            onChange={ev=>{
                                setCostCenter(ev.target.value)
                                filterPayrolls({value:ev.target.value,field:'costCenter'})
                                // payrollRun.filteredPayrolls=filteredPayrolls
                                //   props.onChange({type:ev.target.value,hrs})
                            }}
                        >
                            <MenuItem key={0} value={0}>All Cost Centers</MenuItem>
                            <MenuItem key={0} value={-1}>Separate Cost Centers</MenuItem>
                            {costCenters&&costCenters.map(ot=><MenuItem key={ot.id} value={ot.code||ot.name}>{ot.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item>

                        <Select
                            value={employmentType}
                            onChange={ev=>{
                                setEmploymentType(ev.target.value)
                                filterPayrolls({value:ev.target.value,field:'employmentType'})
                                // setFilteredPayrolls(payrollRun.payrolls.filter(p=>(!site||p.siteName===site)&&(!department||p.departmentName===department)&&(ev.target.value===0||p.employmentType===ev.target.value)&&(!payrollStatus||p.status===payrollStatus)&&(!costCenter||p.costCenterCode===costCenter)))
                                // payrollRun.filteredPayrolls=filteredPayrolls
                                //   props.onChange({type:ev.target.value,hrs})
                            }}
                        >
                            <MenuItem key={0} value={0}>All Employment Types</MenuItem>
                            {employmentTypes&&employmentTypes.map(et=><MenuItem key={et} value={et}>{et}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item>

                        <Select
                            value={site}
                            onChange={ev=>{
                                setSite(ev.target.value)
                                filterPayrolls({value:ev.target.value,field:'site'})
                               //(payrollRun.payrolls.filter(p=>(!employmentType||p.employmentType===employmentType)&&(!department||p.departmentName===department)&&(ev.target.value===0||p.siteName===ev.target.value)&&(!payrollStatus||p.status===payrollStatus)&&(!costCenter||p.costCenterCode===costCenter)))
                                // payrollRun.filteredPayrolls=filteredPayrolls
                                //   props.onChange({type:ev.target.value,hrs})
                            }}
                        >
                            <MenuItem key={0} value={0}>All Sites</MenuItem>
                            {sites&&sites.map(ot=><MenuItem key={ot.id} value={ot.name}>{ot.name}</MenuItem>)}
                        </Select>
                    </Grid>

                    <Grid item>
                        <Select
                            value={payrollStatus}
                            onChange={ev=>{
                                setPayrollStatus(ev.target.value)
                                filterPayrolls({value:ev.target.value,field:'payrollStatus'})
                               // setFilteredPayrolls(payrollRun.payrolls.filter(p=>(!employmentType||p.employmentType===employmentType)&&(!department||p.departmentName===department)&&(!site||p.siteName===site)&&(!ev.target.value||p.status===ev.target.value)))
                                // payrollRun.filteredPayrolls=filteredPayrolls
                                //   props.onChange({type:ev.target.value,hrs})
                            }}
                        >
                            <MenuItem key={0} value={0}>All States</MenuItem>
                            {payrollStates.map(ot=><MenuItem key={ot.id} value={ot.id}>{ot.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item>
                        <Select
                            value={tinStatus}
                            onChange={ev=>{
                                setTinStatus(ev.target.value)
                                filterPayrolls({value:ev.target.value,field:'tinStatus'})
                                // setFilteredPayrolls(payrollRun.payrolls.filter(p=>(!employmentType||p.employmentType===employmentType)&&(!department||p.departmentName===department)&&(!site||p.siteName===site)&&(!ev.target.value||p.status===ev.target.value)))
                                // payrollRun.filteredPayrolls=filteredPayrolls
                                //   props.onChange({type:ev.target.value,hrs})
                            }}
                        >
                            <MenuItem key={0} value={0}>With or Without Tin</MenuItem>
                            {['With Tin', 'Without Tin'].map(ot=><MenuItem key={ot} value={ot}>{ot}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid xs={2}>

                    </Grid>
                    <Grid item>
                        {/*<SelectAllAutocomplete*/}
                        {/*    items={employees}*/}
                        {/*    label="fullName"*/}
                        {/*    placeholder="Filter Employees"*/}
                        {/*    selectAllLabel="Select all"*/}
                        {/*    getOptionLabel={option => option.fullName}*/}
                        {/*    onChange={(val)=>{*/}
                        {/*        console.log(val)*/}

                        {/*        setFilteredPayrolls(payrollRun.payrolls.filter(p=>!val||!val.length||val.some(v=>v.id===p.employeeId)))*/}

                        {/*    }}*/}
                        {/*/>*/}
                        <MultiSelect
                            value={selectedEmployees}

                            options={employees.map(e=>({label:(e.orgID||'')+(e.orgID?'-':'')+e.fullName,value:e.id}))}
                            onChange={values=>{
                                setSelectedEmployees(values)
                                filterPayrolls({value:values,field:'employeeIds'})
                                // setFilteredPayrolls(payrollRun.payrolls.filter(p=>!values||!values.length||values.some(v=>v.value===p.employeeId)))
                            }}
                            labelledBy="Select"
                            valueRenderer={(selected, _options) => {
                                // return selected.length
                                //     ? selected.map(({ label }) => "✔️ " + label)
                                //     : "😶 No Items Selected";
                                if(!selected.length){
                                    return "Filter By Employees"
                                }
                                if(selected.length === employees.length)
                                {
                                    return ["All Employees"]
                                }
                                if(selected.length>3)
                                {
                                  return   selected.slice(0, 3).map(({ label }) => `${label}, `).concat(` + ${selected.length-3}`)
                                }

                                return selected.map(({ label }) => `${label}, `)
                            }}
                        />


                        {/*<Autocomplete*/}
                        {/*    id="combo-box-dem"*/}
                        {/*    multiple*/}
                        {/*    limitTags={2}*/}
                        {/*    onChange={(ev,val)=>{*/}
                        {/*        //console.log(ev,val)*/}

                        {/*        setFilteredPayrolls(payrollRun.payrolls.filter(p=>!val||!val.length||val.some(v=>v.id===p.employeeId)))*/}

                        {/*    }}*/}
                        {/*    options={employees}*/}
                        {/*    getOptionLabel={option => option.fullName}*/}
                        {/*    style={{ width: 300 }}*/}
                        {/*    renderInput={params => <TextField style={{ width: 300 }} {...params} label="Search Employee" variant="outlined" />}*/}
                        {/*/>*/}
                    </Grid>

                    <Grid item alignItems="end">
                        <EmailIcon/>

                    <Select
                        value={emailStatus}
                        onChange={ev=>{
                            setEmailStatus(ev.target.value)
                            // setFilteredPayrolls(payrollRun.payrolls.filter(p=>(!site||p.siteName===site)&&(!department||p.departmentName===department)&&(ev.target.value===0||p.employmentType===ev.target.value)))
                            setFilteredPayrolls(payrollRun.payrolls.filter(p=>(!employmentType||p.employmentType===employmentType)&&(!site||p.siteName===site)&&(!department||p.departmentName===department)&&(ev.target.value<0||(ev.target.value===0?!p.emailSent:p.sentSlipType===ev.target.value))))
                            // payrollRun.filteredPayrolls=filteredPayrolls
                            //   props.onChange({type:ev.target.value,hrs})
                        }}
                    >
                        <MenuItem key={0} value={-1}>All</MenuItem>
                        <MenuItem key={0} value={0}>Not Sent</MenuItem>
                        <MenuItem key={1} value="Final">Final Slip Sent</MenuItem>
                        <MenuItem key={2} value="Draft">Draft Slip Sent</MenuItem>
                        <MenuItem key={3} value="Processing">Sending</MenuItem>
                        <MenuItem key={4} value="Failed">Failed to Send</MenuItem>


                    </Select>
                </Grid>
                </Grid>
                <TabbedShowLayout>
                    <Tab label="Payroll Summery" path="summery">
                        <PayrollSummeryReport  sort={sort} handelSort={handelSort}  emps={emps} logo={company&&company.logo} companyName={props.companyName} payrollRun={payrollRun}  site={site} employmentType={employmentType} department={department}  filteredPayrolls={filteredPayrolls} />
                    </Tab>
                    <Tab label="Payroll Sheet" path="sheet">
              <PayrollSheetReport companySettings={props.companySettings} onSeparateByCostCenter={onSeparateByCostCenter} calendar={props.calendar} logo={company&&company.logo} companyName={props.companyName} templates={{templateEarnings, templateLoans, templateDeductions, templateExpenses}} sort={sort} handelSort={handelSort} site={site} employmentType={employmentType} department={department}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun}/>
                    </Tab>
                    <Tab label="Bank Transfer List" path="bank">
                        <PayrollBankList onSeparateByCostCenter={onSeparateByCostCenter} site={site} logo={company&&company.logo} sort={sort} handelSort={handelSort}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} costCenter={costCenter}/>
                    </Tab>
                    <Tab label="Payslip" path="slips">
                        <PayslipList onSeparateByCostCenter={onSeparateByCostCenter}  logo={company&&company.logo} refresh={refresh} filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} costCenter={costCenter}/>
                    </Tab>
                    <Tab label="IncomeTax Declaration" path="tax">
                        <TaxDeclarationReport onSeparateByCostCenter={onSeparateByCostCenter} tinStatus={tinStatus} company={company||{address:{}}}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} costCenter={costCenter}/>
                    </Tab>
                    <Tab label="Pension Declaration" path="pension">
                        <PensionDeclarationReport onSeparateByCostCenter={onSeparateByCostCenter} tinStatus={tinStatus} company={company||{address:{}}}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} costCenter={costCenter}/>
                    </Tab>
                    <Tab label="Payroll Journal" path="journal">
                        <PayrollJournal accounts={accounts} logo={company&&company.logo}  setPayrollJournal={x=>recalculate(x,payrollRun,filteredPayrolls,false,accounts)} settingId={settingId} company={company||{address:{}}}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} templates={{templateEarnings, templateLoans, templateDeductions, templateExpenses}} />
                        {/*<PayrollJournal  settingId={settingId} company={company||{address:{}}}  filteredPayrolls={filteredPayrolls.filter(p=>p.costCenterCode==='SALES')} payrollRun={payrollRun} templates={{templateEarnings, templateLoans, templateDeductions, templateExpenses}} />*/}

                    </Tab>
                    <Tab label="Payroll Loans" path="loans">
                        <PayrollLoan  logo={company&&company.logo}  sort={sort} handelSort={handelSort}  settingId={settingId} company={company||{address:{}}}  filteredPayrolls={filteredPayrolls} payrollRun={payrollRun} templates={{templateEarnings, templateLoans, templateDeductions, templateExpenses}} />
                    </Tab>
                </TabbedShowLayout>
            </CardContent>
        </Card>
        </MultiCurrencyContext.Provider>
    )
};

export default connect(state=>({companyName:state.user.currentCompany.name, calendar:state.setting.calendar, companySettings: state.setting.companySettings,templateEarnings: state.payrollStatus.templateEarnings,templateDeductions: state.payrollStatus.templateDeductions,templateExpenses: state.payrollStatus.templateExpenses,templateLoans: state.payrollStatus.templateLoans})) (PayrollReports);
