import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//     formControl: {
//         margin: theme.spacing(1),
//         width: 300
//     },
//     indeterminateColor: {
//         color: "#f50057"
//     },
//     selectAllText: {
//         fontWeight: 500
//     },
//     selectedAll: {
//         backgroundColor: "rgba(0, 0, 0, 0.08)",
//         "&:hover": {
//             backgroundColor: "rgba(0, 0, 0, 0.08)"
//         }
//     }
// }));
//
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250
//         }
//     },
//     getContentAnchorEl: null,
//     anchorOrigin: {
//         vertical: "bottom",
//         horizontal: "center"
//     },
//     transformOrigin: {
//         vertical: "top",
//         horizontal: "center"
//     },
//     variant: "menu"
// };






const MultiSelectAll = ({options, onChange, value}) => {
    // const classes = useStyles();
    const [selected, setSelected] = useState(value);
    const isAllSelected =
        options.length > 0 && selected.length === options.length;

    const handleChange = (event) => {
        const value = event.target.value;
        // console.log({value})
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options.map(o=>o.value));
            onChange(selected.length === options.length ? [] : options.map(o=>o.value))
            return;
        }
        else if(value.length) {
            setSelected(value);
            onChange(value,value[value.length - 1])
        }


    };

    return (

            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                renderValue={(selected) => selected.map(s=>options.find(o=>o.value===s).textLabel).join(", ")}

            >
                <MenuItem
                    value="all"

                >
                    <ListItemIcon>
                        <Checkbox
                           checked={isAllSelected}
                           onChange={handleChange}
                            indeterminate={
                                selected.length > 0 && selected.length < options.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText
                          primary="Select All"
                    />
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.key} value={option.value}>
                        <ListItemIcon>
                            <Checkbox checked={selected.indexOf(option.value) > -1} />
                        </ListItemIcon>
                        {/*<ListItemText primary={option} />*/}
                        {option.label||option.textLabel}
                    </MenuItem>
                ))}
            </Select>

    );
};

export default MultiSelectAll;



