import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {TimeSheetItem} from "./timesheetItem";
import Button from "@material-ui/core/Button";
import MaterialCheckBoxGroup from "../../UIComponents/materialCheckBoxGroup";
import payrollStat from "../PayrollStat/payrollStat";
import {DatePicker} from "@material-ui/pickers";
import moment from "moment";
import Loader from "react-loader-spinner";
import {Typography} from "../../UIComponents/Wrappers";
import {Divider, Icon, TextField} from "@material-ui/core";
import PopoverData from "./PopoverData";
// import {useHasChanged} from "../../Helpers/hooks";
import ErrorIcon from "@material-ui/icons/ErrorOutlineOutlined"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import _ from 'lodash'
import {DateRangePicker} from "@progress/kendo-react-dateinputs";
const employmentTypes = [
    { id: 'FullTime', name: 'FullTime' },
    { id: 'Contract', name: 'Contract' },
    { id: 'Casual', name: 'Casual' },
    { id: 'PartTime', name: 'PartTime' },
]
let tempSet =false

const SyncMates = ({newEmps,inactiveEmps,updatedEmps,updateSites,employmentType, groupedTimesheet, loading, error, handleClose, payrollDate, saving,timesheet,
                       done, atDate, allSelected, periodStart, periodEnd}) => {

    const dispatch = useDispatch();
   // const [atDate,setAtDate] = useState(moment());
   //  const hasDateChanged = useHasChanged(payrollDate)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popData, setPopData] = React.useState(null);
    const [searchKey, setSearchKey] = React.useState(null);
    const [start, setStart] = React.useState(periodStart);
    const [end, setEnd] = React.useState(periodEnd);
    // const [selectedNewEmps,setSelectedNewEmps] = useState([]);
    // const [selectedInactiveEmps,setSelectedInactiveEmps] = useState([]);
    useEffect(() => {

           loadData()

    }, [])


    useEffect(() => {
       handleClose(done)
    }, [done])

    // useEffect(() => {
    //   //  setAtDate(moment.min(moment(),moment(payrollDate).endOf("month")))
    //     if(hasDateChanged)
    //     {
    //         dispatch({type: 'SET_MATES_ATDATE', payload: moment.min(moment(),moment(payrollDate).endOf("month"))})
    //
    //     }
    //
    //
    // }, [payrollDate])

    //  console.log(newEmps)

    const [periodRange, setPeriodRange] = React.useState({
        start: moment(periodStart).toDate(),
        end: moment(periodEnd).toDate()
    });
    if (!tempSet&& (moment(periodStart).format('YYYY-MM-DD') !== moment(periodRange.start).format('YYYY-MM-DD') ||
        moment(periodEnd).format('YYYY-MM-DD') !== moment(periodRange.end).format('YYYY-MM-DD')
    )) {
        setPeriodRange({
            start: moment(periodStart).toDate(),
            end: moment(periodEnd).toDate()
        })
    }

    const handlePeriodChange = (ev) => {
        const range = ev.target.value;
        tempSet = true
        setPeriodRange(range)
        if(range&&range.start&&range.end) {
            onPayrollDateChanged({periodStart:range.start,periodEnd:range.end})
            tempSet=false
        }
    }



    const handleAccept = () => {
        dispatch({type: 'SAVE_MATES_CHANGES', payload:{newEmps,inactiveEmps,updatedEmps,timesheet:timesheet.filter(t=>t.selected), updateSites}})
    }
    const handleDateChange = (date) => {
        // setAtDate(date)
        dispatch({type: 'SET_MATES_ATDATE', payload: date})
        dispatch({type: 'LOAD_MATES_TIMESHEET', payload: {atDate:date, month:payrollDate, periodEnd, periodStart}})
    }
    const onPayrollDateChanged = (dates) => {
        dispatch({type:'CHANGE_PAYROLL_DATE',payload:dates})
    }
    const loadData = () => {
        dispatch({type: 'LOAD_MATES_CHANGES', payload: {startDate:periodStart,endDate:periodEnd}})
        dispatch({type: 'LOAD_MATES_TIMESHEET', payload: {atDate, month:payrollDate,
                periodStart:moment(start).format('YYYY-MM-DD'),
                periodEnd:moment(end).format('YYYY-MM-DD')}})
    }
    const handleSelectEmpCheck= (value, field="newEmps") => {
        const ids = value.map(v=>v._id)
        //console.log(ids)
        const data = {newEmps, inactiveEmps, updatedEmps}
        const nEmps = data[field].map(e=>{
            if(ids.includes(e._id)) {
                e.payrollStatus = "Applied"
            }
            else {
                e.payrollStatus = "Pending"
            }
            return e
        })
        dispatch({type: 'SET_MATES_EMPS', payload:{...data, [field]:nEmps}})
    }
    if(error) return <div  style={{height:'30vh', position:"relative", textAlign:"Center"}} >
        <Icon fontSize={"large"}><ErrorIcon/></Icon>
        <Typography size="md" >Sorry, something went wrong </Typography>
        <Typography size="sm" >Make sure your internet connection is working </Typography>
        <Button onClick={loadData}>Retry</Button>
    </div>

    if (loading) return <div style={{height:'30vh', position:"relative", textAlign:"Center"}}>
        <Loader
            visible={loading}
            type="Bars"
            color="#000"
            height={60}
            width={60}
        />
        <Typography >Getting Changes From Mates HCM ... </Typography>
    </div>

    const handleSelected = (e, selected) => {
        dispatch({type:"TIMESHEET_SELECTED_CHANGED", payload:{timesheet:e,selected}})
    }



    return (
        <div style={{minHeight:'100%', position:"relative"}}>
            <Grid container spacing={5}>
                <Grid item>
                    <MaterialCheckBoxGroup value={newEmps.filter(e=>e.payrollStatus==="Applied")} onChange={handleSelectEmpCheck}
                                           itemLabel="fullName"
                                           type="employmentType"
                                           subType="joinTeam"
                                           items={newEmps}
                                           label="New Employees"
                                           onView={(e,item)=>{
                                               setAnchorEl(e.currentTarget)
                                               setPopData(item)
                                           }}
                                           hint="Selected Employees will be added to payroll"
                    />

                </Grid>
                <Grid item>
                    <MaterialCheckBoxGroup value={inactiveEmps.filter(e=>e.payrollStatus==="Applied")} onChange={val=>handleSelectEmpCheck(val,'inactiveEmps')}
                                           itemLabel="fullName"
                                           items={inactiveEmps}
                                           onView={(e,item)=>{
                                               setAnchorEl(e.currentTarget)
                                               setPopData(item)
                                           }}
                                           label="Terminated Employees"
                                           hint="Selected Employees will be removed from payroll"
                    />
                </Grid>
                <Grid item>
                    <MaterialCheckBoxGroup value={updatedEmps.filter(e=>e.payrollStatus==="Applied")} onChange={val=>handleSelectEmpCheck(val,'updatedEmps')}
                                           itemLabel="fullName"
                                           items={updatedEmps}
                                           onView={(e,item)=>{
                                               setAnchorEl(e.currentTarget)
                                               setPopData({...item, updateType:true})
                                           }}
                                           label="Adjustments"
                                           type="Adjustment"
                                           hint="Selected Employees will be updated in this payroll"
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        views={["year", "month"]}
                        label="Payroll Month"
                        margin="dense"
                        // minDate={new Date("2018-03-01")}
                        // maxDate={new Date("2018-06-01")}

                        value={payrollDate} onChange={payrollDate=>onPayrollDateChanged({payrollDate})} />
                    {/*<DateRangePicker value={periodRange} format={'MMMM d'} onChange={handlePeriodChange} />*/}




                    <DatePicker
                        label="From"
                        margin="dense"
                        value={start} onChange={setStart} />

                    <DatePicker
                        label="To"
                        margin="dense"
                        value={end} onChange={setEnd}  />


                    <FormControlLabel
                        labelPlacement='bottom'
                        control={<Switch
                            checked={updateSites}
                            onChange={ev=> dispatch({type:'SET_MATES_UPDATE_SITES',payload:ev.target.checked})}
                            value="Update Sites"
                            color="primary"
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                        }
                        label="Update Sites"
                    />


                    <FormControlLabel
                        labelPlacement='bottom'
                        control={<Switch
                            checked={allSelected}
                            onChange={ev=> dispatch({type:'TIMESHEET_SELECTED_CHANGED_ALL',payload: {selected:ev.target.checked}})}
                            value="Select All"
                            color="primary"
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                        }
                        label="Select All"
                    />
                </Grid>

            </Grid>

            <Divider style={{margin:30}}/>
            {/*{searchKey}*/}
            <Grid container>
                <Grid item>
                    <DatePicker  label="Employee Allocation as Of" value={atDate} onChange={handleDateChange} />

                </Grid>
                <Grid item>
                    <FormControl fullWidth >
                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                            Employment Type
                        </InputLabel>

                        <Select
                            value={employmentType}
                            onChange={ev=>{
                                // setSite(ev.target.value)
                                dispatch({type: 'TIMESHEET_EMPLOYMENT_TYPE_CHANGED', payload: {employmentType:ev.target.value}})
                            }}
                        >
                            <MenuItem key={0} value={null}>All Employees</MenuItem>
                            {employmentTypes.map(ot=><MenuItem key={ot.id} value={ot.name}>{ot.name}</MenuItem>)}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item>
                    <TextField style={{position:"absolute", right:10}} placeholder="Search Names" value={searchKey} onInput={ev=>setSearchKey(ev.target.value)} />

                </Grid>
            </Grid>
        {/*<Typography size="md"> Pay Groups</Typography>*/}
   <Divider/>
            <Grid container spacing={5}>
       {groupedTimesheet.map(t=><Grid item>
           <Checkbox
               color="primary"
               checked={t.selected}
               onChange={ev=>dispatch({type:'TIMESHEET_GROUP_SELECTED_CHANGED',payload:{group:t.group,selected:ev.target.checked}})}
               inputProps={{ 'aria-label': 'controlled' }}
           /> {t.group}
           {_.orderBy(t.emps.filter(e=>!searchKey||(e.fullName&&e.fullName.toLowerCase().includes(searchKey.toLowerCase()))),'fullName').map((e,i)=>
               <TimeSheetItem totalDays={moment(periodRange.end).diff(periodRange.start,'days')+1} index={i} timesheet={e} setSelected={selected=>handleSelected(e,selected)}/>)}
       </Grid>)}
   </Grid>


            <div style={{position:"absolute", right:0, bottom:0}}>
                <Button onClick={()=>handleClose(true)}>Cancel</Button>
                <Button onClick={loadData}>Reload</Button>
                <Button disabled={saving} onClick={handleAccept} color={"primary"}><Loader
                    visible={saving}
                    type="Bars"
                    color="#000"
                    height={40}
                    width={40}
                /> {!saving&&<span>Accept</span>}</Button>
            </div>

          <PopoverData data={popData} anchor={anchorEl} handleClose={()=>setAnchorEl(null)}/>


        </div>
    );
};

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
//     return {
//        ...propsFromState,
//
//
//     };
// };

export default connect(state=>({
    newEmps: state.mates.newEmps,
    inactiveEmps: state.mates.inactiveEmps,
    updatedEmps: state.mates.updatedEmps,
    groupedTimesheet: state.mates.groupedTimesheet,
    timesheet: state.mates.timesheet,
    payrollDate: state.runPayroll.payrollDate,
    periodEnd: state.runPayroll.periodEnd,
    periodStart:state.runPayroll.periodStart,
    loading: state.mates.loading,
    saving: state.mates.saving,
    error: state.mates.error,
    done: state.mates.done,
    atDate : state.mates.atDate ||  state.runPayroll.periodEnd ,
    updateSites: state.mates.updateSites,
    allSelected: state.mates.allSelected,
    employmentType: state.mates.employmentType,
})) (SyncMates);
