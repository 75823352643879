const initialState = {
    terminations: [],
    newHires: [],
    teams: [],
    notFoundTerminations: []

};

export default (previousState = initialState, { type, payload }) => {

    if (type === 'SET_TIK_TERMINATIONS') {
        return {...previousState,   terminations: payload};
    }
    if (type === 'SET_TIK_NEW_HIRES') {
        return {...previousState,   newHires: payload};
    }
    if (type === 'SET_TIK_TEAMS') {
        return {...previousState,   teams: payload};
    }
    if (type === 'SET_TIK_NOT_FOUND_TERMINATIONS') {
        // console.log({payload})
        return {...previousState,   notFoundTerminations: payload};
    }

    return previousState;
}
